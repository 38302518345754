import { useStore } from "vuex";

import { Agenda } from "@/store/agenda/types";
import { DropdownItem } from "@/components/types";

export function useDropdownOptions() {
  const store = useStore();

  async function handleRestriction(agenda: Agenda) {
    const response = await store.dispatch("updateAgendaRestriction", {
      _id: agenda.id,
      ie_agenda_restrita: !agenda.ie_agenda_restrita,
    });
    if (response?.status === 200) agenda.ie_agenda_restrita = !agenda.ie_agenda_restrita;
  }

  async function handleAppointment(agenda: Agenda) {
    const response = await store.dispatch("updateAgendaAppointment", {
      _id: agenda.id,
      ie_hora_marcada: !agenda.ie_hora_marcada,
    });
    if (response?.status === 200) agenda.ie_hora_marcada = !agenda.ie_hora_marcada;
  }

  function getDropdownOptions(agenda: Agenda, module: string): DropdownItem[] {
    return [
      {
        label: "Visualizar",
        icon: "fas fa-info-circle",
        to: { name: `agenda-${module}-edit`, params: { _id: String(agenda.id) } },
      },
      {
        label: "Atendimentos",
        icon: "far fa-calendar-alt",
        to: { name: "agenda-attendance", params: { _id: String(agenda.id) } },
      },
      {
        label: "Bloqueios",
        icon: "far fa-calendar-times",
        to: { name: "agenda-lock", params: { _id: String(agenda.id) } },
      },
      {
        label: "Editar",
        icon: "fas fa-edit",
        to: { name: `agenda-${module}-edit`, params: { _id: String(agenda.id) } },
      },
      {
        label: "Remover",
        icon: "fas fa-trash-alt",
        to: { name: "agenda-delete", params: { _id: String(agenda.id) } },
      },
      {
        label: agenda.ie_agenda_restrita ? "Desocultar" : "Ocultar",
        icon: agenda.ie_agenda_restrita ? "fas fa-eye" : "fas fa-eye-slash",
        command: () => handleRestriction(agenda),
      },
      {
        label: agenda.ie_hora_marcada ? "Sem hora marcada" : "Com hora marcada",
        icon: "fas fa-clock",
        command: () => handleAppointment(agenda),
      },
    ];
  }

  return { getDropdownOptions };
}
